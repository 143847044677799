const Button = ({ buttonLabel, onClickFunc = () => { } }) => {
  return (
    <button
      type="button"
      className="!border !border-transparent rounded-full font-bold transition ease-in-out text-center font-body no-underline hover:no-underline inline-flex items-center justify-center text-2xl px-8 py-2.5 text-white bg-[#3570E6] hover:!bg-primary-hover active:!bg-primary-hover active:scale-[0.98] focus:outline-none focus-visible:outline-none focus:ring-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-primary-hover"
      onClick={onClickFunc}
    >
      {buttonLabel}
    </button>
  )
}

export default Button
