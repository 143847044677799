const CollapseMenu = () => {
  return (
    <span className="!p-4 flex flex-col justify-center align-middle group xl:hidden">
      <span className="bg-typo no-touch-hover:group-hover:bg-brand-typo group-active:bg-brand-typo w-7 rounded h-3px !mb-1 group-active:rotate-45 group-active:translate-y-[5.5px] transition ease-in-out rotate-0 border border-solid border-[#171717]">
      </span>
      <span className="bg-typo no-touch-hover:group-hover:bg-brand-typo group-active:bg-brand-typo w-7 rounded h-3px !mt-1 group-active:-rotate-45 group-active:-translate-y-[5.5px] transition ease-in-out rotate-0 border border-solid border-[#171717]">
      </span>
    </span>
  )
}

export default CollapseMenu
