import CollapseMenu from '../assets/images/CollapseMenu'
import RemoveBGLogo from '../assets/images/RemoveBgFullLogo.svg'
import Button from './UI/Button'
import NavbarLI from './UI/NavbarLI'

const Navbar = () => {
  return (
    <div className="h-[72px] mx-auto w-screen px-8">
      <div className="navbarContents w-full flex justify-between shadow-sm">
        <div className="logo">
          <img src={RemoveBGLogo} alt="" className='mr-8' />
        </div>
        <ul className="links flex grow gap-4 max-xl:hidden">
          <NavbarLI text="Remove Background" />
          <NavbarLI text="How to Use" />
          <NavbarLI text="Tools and API" />
          <NavbarLI text="Pricing" />
        </ul>
        <ul className="authentication flex items-center gap-4 max-xl:hidden">
          <NavbarLI text="Log in" />
          <li>
            <Button buttonLabel={"Sign Up"} />
          </li>
        </ul>
        <CollapseMenu />
      </div>
    </div >
  )
}

export default Navbar
